<script setup>
import { ref, watch, nextTick } from 'vue';

const props = defineProps({
    blok: {
        type: Object,
        default: () => ({}),
    },
});

const wordList = props.blok.headingAnimatedWords ? props.blok.headingAnimatedWords.split('|') : [];
const currentWordIndex = ref(0);
const blurred = ref(true); // This ref will control the blur state

watch(currentWordIndex, (newValue, oldValue) => {
    if (newValue !== oldValue) {
        // Ensure the word starts blurred when it changes
        blurred.value = true;
        // After a delay that matches the blur animation, unblur the word
        setTimeout(() => {
            blurred.value = false;
        }, 200); // This duration should match your CSS animation duration
    }
});

// Function to cycle to the next word
const cycleWord = () => {
    if (wordList.length === 0) return;
    currentWordIndex.value = (currentWordIndex.value + 1) % wordList.length;
};

onMounted(() => {
    // Start the cycle with the first word blurred
    setTimeout(() => {
        blurred.value = false;
    }, 500); // Delay to start the unblur effect after initial mount

    // Set the interval to cycle words
    setInterval(() => {
        // Ensure we blur the current word before changing it
        blurred.value = true;

        // Change the word after a short delay
        setTimeout(cycleWord, 10); // Delay should be less than the animation time to allow blur to be seen
    }, 3000); // Total duration before cycling to the next word
});
</script>

<template>
    <section
        class="bg-gradient-to-r to-gray-50 from-gray-200 dark:from-midnight-blue dark:to-deep-blue"
    >
        <div class="max-w-screen-xl px-4 mx-auto lg:px-6">
            <div class="flex flex-col items-center lg:items-end lg:flex-row">
                <div class="my-12 text-center md:max-w-3xl lg:text-left xl:shrink-0">
                    <div>
                        <h1
                            class="font-serif text-4xl font-extrabold tracking-tight leading-none text-gray-900 sm:text-6xl dark:text-white"
                        >
                            {{ blok.headingFirstWords }}
                            <span
                                :class="[
                                    { blurred: blurred },
                                    'animated-word',
                                    'text-transparent',
                                    'bg-clip-text',
                                    'bg-gradient-to-r',
                                    'from-green-600',
                                    'to-minty-green',
                                ]"
                            >
                                {{ wordList[currentWordIndex] }}
                            </span>
                            {{ blok.headingTailWords }}
                        </h1>
                        <p
                            class="mt-4 text-base font-normal text-gray-500 md:max-w-3xl md:mx-auto sm:text-xl dark:text-gray-400"
                        >
                            {{ blok.paragraph }}
                        </p>
                    </div>

                    <div class="flex items-center justify-center gap-4 mt-8 lg:justify-start">
                        <StoryblokComponent
                            v-for="(item, index) in blok.ctas"
                            :key="item._uid"
                            :blok="item"
                            :id="item._uid"
                            :index="index"
                        />
                    </div>

                    <div
                        class="flex items-center justify-center gap-5 mt-12 lg:justify-start sm:gap-6"
                    >
                        <img
                            class="dark:hidden inline-block w-36"
                            :src="blok.smallImage1.filename"
                            :alt="blok.smallImage1.alt"
                        />
                        <img
                            class="hidden dark:block w-36"
                            :src="blok.smallImage1Dark.filename"
                            :alt="blok.smallImage1Dark.alt"
                        />
                        <!-- Hiding this divider line until we add a second item here-->
                        <!--div class="w-px h-16 bg-gray-200 dark:bg-gray-500"></div-->
                        <img
                            class="ml-1 dark:hidden inline-block w-32"
                            :src="blok.smallImage2.filename"
                            :alt="blok.smallImage2.alt"
                        />
                        <img
                            class="ml-1 hidden dark:block w-32"
                            :src="blok.smallImage2Dark.filename"
                            :alt="blok.smallImage2Dark.alt"
                        />
                    </div>
                </div>

                <div
                    class="overflow-hidden relative w-[320px] h-[280px] md:w-[460px] md:h-[400px] object-left-bottom object-fill"
                >
                    <img
                        class="absolute bottom-0 lg:left-0"
                        :src="`${blok.image.filename}/m/460x0`"
                        alt="Advertiser using Tiptapp"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
/* Apply the blur by default */
.animated-word {
    filter: blur(10px);
}

/* Define the keyframes for unblurring */
@keyframes unblur {
    0% {
        filter: blur(10px);
    }
    100% {
        filter: blur(0);
    }
}

/* Apply the unblur animation when not blurred */
.animated-word:not(.blurred) {
    animation: unblur 0.5s ease forwards;
}

/* Define the keyframes for blurring */
@keyframes blur {
    0% {
        filter: blur(0);
    }
    100% {
        filter: blur(10px);
    }
}

/* Apply the blur animation when blurred */
.animated-word.blurred {
    animation: blur 0.1s ease forwards;
}
</style>
